import React, { FC } from 'react';
import LayoutMain from './ds/layout/LayoutMain';
import Section, { darkTheme } from './ds/layout/LayoutSection';
import { H1 } from './ds/primitive/Typography';
import styled from 'styled-components';
import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';
import { TechniquePageFragment } from '../entities/operationResults';
import Footer from './organisms/Footer';
import { Section as DeprecatedSection } from './atoms/Section';
import RichContent from './RichContent';

interface Props {
    entry: TechniquePageFragment;
    // tslint:disable-next-line:no-any
    location: WindowLocation & { state: any };
}

// export const fragment = graphql`
//     fragment TechniqueNewPageFragment on Craft_Technique {
//         id
//         title
//         # hero {
//         #     ...HeroFragment
//         # }
//         # callToAction {
//         #     ...CTAFragment
//         # }
//         # teamMemberQuote {
//         #     ...TeamMemberQuoteFragment
//         # }
//         # directContact {
//         #     ...DirectContactFragment
//         # }
//     }

//     # ${heroFragment}
//     # ${teamMemberQuoteFragment}
//     # ${directContactFragment}
// `;

/**
 * TODO: Fix this mess
 */
const TechniqueTemplate: FC<Props> = ({ entry, location }) => {
    const hero = entry.hero && entry.hero[0];
    const footer = entry.directContact && entry.directContact[0];
    // const ctaList = ((entry && entry.callToAction) || []) as CTAFragment[];
    // const teamMember = entry.teamMemberQuote && entry.teamMemberQuote[0];

    return (
        <>
            {/* <DeprecatedSection>{hero && <Hero data={hero} ratio={759 / 1168} />}</DeprecatedSection> */}
            <Header theme={darkTheme} level={1} padding>
                <LayoutMain>{hero && <H1 variant="huge">{hero.introTitle}</H1>}</LayoutMain>
            </Header>
            {/** todo: add in later as a hero replacement */}
            {entry.richContent && (
                <RichContent
                    contents={entry.richContent}
                    sourceId={entry.id}
                    layoutSectionProps={{ theme: darkTheme }}
                />
            )}
            {/* <ArticleSection level={0} padding>
                <LayoutMain>
                    <ContentArticle>
                        <Lead variant="huge">
                            Er is al veel geschreven over microservices. Het concept bestaat al vele jaren en de laatste
                            jaren is er zelfs kortstondig een echte hype over ontstaan. Maar het is zeker niet voor
                            alles en iedereen de beste oplossing. Bij Oberon adviseren we het daarom vooral als het ook
                            echt iets toevoegt. En dan moet je goed kijken naar de bekende voordelen en nadelen. Maar
                            bij Oberon hebben we in de loop der jaren ook in meerdere projecten genoeg ervaring opgedaan
                            om er met een positief kritische blik naar de te kijken en alleen op de juiste moment en
                            plekken gebruik te maken van microservices.
                        </Lead>
                        <H2 variant="small">Wat is het</H2>
                        <Body variant="small">
                            Er is niet één definitie te vinden, maar wikipedia omschrijft het als: Microservices are a
                            software development technique that arranges an application as a collection of loosely
                            coupled services.
                        </Body>
                        <Body variant="small">
                            Eén applicatie wordt dus opgeknipt of opgebouwd uit allemaal kleine onderdelen die
                            zelfstandig verantwoordelijk zijn voor hun eigen stukje van de applicatie. Deze kleine
                            stukjes zijn dus autonoom, communiceren onderling over hun resultaten en zijn zo gezamenlijk
                            een grote applicatie. De tegenhanger is een monolith. Een groot monolitisch systeem wat
                            alles doet, wellicht opgedeeld in modules, maar allemaal in één omgeving, één taal en één
                            hosting-oplossing.
                        </Body>
                        <H2 variant="small">Bekende voordelen</H2>
                        <Body variant="small">
                            De kleine stukjes (de microservices) moeten daarmee op zichzelf minder complex, makkelijker
                            onderhoudbaar en beter schaalbaar kunnen zijn dan wanneer alles in één grote applicatie zit.
                            Elke service kan in zijn eigen optimale architectuur worden opgezet. Ook kan elke service
                            door een onafhankelijk team van ontwikkelaars worden onderhouden. Voorzie je dus dat
                            bepaalde onderdelen van je applicatie enorm kunnen exploderen, terwijl andere delen wellicht
                            minder zwaar belast hoeven te worden, dan is het aan te raden in ieder geval dat ene deel
                            volledig los te koppelen van de rest van je systeem.
                        </Body>
                        <H2 variant="small">Bekende nadelen</H2>
                        <Body variant="small">
                            Hoewel de onderdelen op zichzelf minder complex zijn, is het totaal dat vaak niet. Het
                            totale systeem bevat veel onderdelen, die veel moeten communiceren. Met veel ‘bewegende’
                            onderdelen zijn er veel plekken waar er iets mis kan gaan en is het nogal eens lastig te
                            achterhalen waar dat nou eigenlijk zit. Heb je eigenlijk maar een klein aantal
                            ontwikkelaars, ook op de lange termijn, dan werkt dat voor die ontwikkelaars soms alleen
                            maar tegen.
                        </Body>
                    </ContentArticle>
                </LayoutMain>
                {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} withMargin align="right" />}
            </ArticleSection>
            <DeprecatedSection>{teamMember && <TeamMemberQuote data={teamMember} />}</DeprecatedSection>
            <Section level={0} padding>
                <LayoutMain>
                    <ContentArticle>
                        <H2 variant="small">Oplossingen</H2>
                        <Body variant="small">
                            Veel ‘microservices’ worden als pakketje al aangeboden tegenwoordig in de cloud. Amazon
                            (AWS), Microsoft (Azure) en Google (Google Cloud Platform) bieden direct toegang tot kant en
                            klare microservices. Vaak doen deze services echt maar één ding en kunnen vooral goed
                            communiceren met andere services van hetzelfde platform.
                        </Body>
                        <H2 variant="small">Voorbeelden</H2>
                        <Body variant="small">
                            Zoals geschreven, gebruiken we bij Oberon graag microservices, maar zetten we ze niet altijd
                            en overal in. Sommige services via de cloud zijn tegenwoordig bijna onmisbaar (bijv. een CDN
                            voor razendsnelle websites); we bouwen zelden meer zonder. Maar dat wil niet zeggen dat de
                            hele applicatie uit microservices bestaat. Het wisselt;
                        </Body>
                        <List variant="small">
                            <li>
                                Voor een startup ontwikkelde we een bijna volledig uit AWS microservices opgebouwd
                                systeem. Van S3, DynamoDB, Cognito en Lambda functies van AWS gekoppeld aan een
                                Contenful headless CMS.{' '}
                            </li>
                            <li>
                                Voor de meeste headless CMS oplossingen kiezen we Craft CMS via GraphQL en Gatsby
                                uitgerold op S3 via ons eigen (maar open-source) plugin. Maar ook Prismic of Contentful
                                zijn opties. Authenticatie vindt soms plaats via Cognito.{' '}
                            </li>
                            <li>
                                Op dit moment koppelen we weliswaar serieuze pakketten als Shopware, Storyblok en PIM
                                software op een ‘lichte’ manier aan elkaar. Losse services die hun eigen
                                verantwoordelijkheden hebben.
                            </li>
                        </List>
                        <H2 variant="small">Stappenplan</H2>
                        <Body variant="small">
                            Heb je het idee of juist nog geen idee of een microservices architectuur voor jouw platform
                            de beste oplossing is, denken we graag met je mee. We zijn daar, zoals altijd bij Oberon,
                            kritisch in. Voorzie je de komende jaren groei en de mogelijkheid om de onderdelen onderling
                            goed te (laten) onderhouden en heb je behoefte aan echt goede schaalbaarheid, dan biedt het
                            je veel. Maar weet je het nog niet, is het nog lastig inschatten, kan je ook meer
                            ‘monolitisch’ beginnen. Zorg dat je applicatie doet wat het moet doen, dat het je business
                            ondersteunt, en dat je de draagkracht hebt om het door te ontwikkelen. Als dat allemaal gaat
                            loopt, kan je altijd nog, ook in kleine stappen je applicatie ombouwen naar een microservice
                            architectuur.
                        </Body>
                        <Body variant="small">
                            Doe dat niet in 1x, maar in stapjes. Kies een onderdeel van je applicatie die een business
                            behoefte ondersteunt en zet deze opnieuw en los op, en schakel over. Zo ga je stap voor stap
                            over.
                        </Body>
                    </ContentArticle>
                </LayoutMain>
                {ctaList[1] && <CTA sourceId={entry.id} data={ctaList[1]} withMargin align="right" />}
            </Section>
            <Section level={1} padding>
                <LayoutMain>
                    <BlockRelatedPages
                        cards={[
                            <a href="#" key={0}>
                                <CardPage
                                    title="De X beste technologieën om een microservices architectuur te bouwen"
                                    type="blog"
                                    imageProps={{
                                        ratio: 576 / 383,
                                        src: 'https://source.unsplash.com/random/1152x766',
                                    }}
                                />
                            </a>,
                            <a href="#" key={1}>
                                <CardPage
                                    title="Het verhaal achter razendsnelle websites en onze populaire Gatsby plugin"
                                    type="blog"
                                    imageProps={{
                                        ratio: 576 / 383,
                                        src: 'https://source.unsplash.com/random/1153x767',
                                    }}
                                />
                            </a>,
                        ]}
                    />
                </LayoutMain>
            </Section> */}
            <DeprecatedSection variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} />}</DeprecatedSection>
        </>
    );
};

const Header = styled(Section)`
    width: ${cssCalcBetweenViewport(560, 960)};
`;

// const ArticleSection = styled(Section)`
//     @media screen and (${theme.mediaQueries.horizontal}) {
//         padding-left: ${cssCalcBetweenViewport(80 * 2, 192 * 2)};
//     }
// `;

// const Lead = styled(Body)`
//     @media screen and (${theme.mediaQueries.horizontal}) {
//         margin-left: ${cssCalcBetweenViewport(-80, -192)};
//     }
// `;

export default TechniqueTemplate;
