import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { TechniquePageFragment, TechniquePageQuery } from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { WindowLocation } from '@reach/router';
import TechniqueTemplate from './TechniqueTemplate';

interface Props {
    data: TechniquePageQuery;
    // tslint:disable-next-line:no-any
    location: WindowLocation & { state: any };
}

export const query = graphql`
    query TechniquePageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...TechniquePageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment TechniquePageFragment on Craft_Technique {
        id
        title
        hero {
            ...HeroFragment
        }
        richContent {
            ...RichContentFragment
        }
        directContact {
            ...DirectContactFragment
        }
        # intro {
        #     ...IntroFragment
        # }
        # supportive {
        #     ...SupportiveFragment
        # }
        # trust {
        #     ...TrustFragment
        # }
        # cases {
        #     ...CaseTilesFragment
        # }
        # callToAction {
        #     ...CTAFragment
        # }
        # teamMemberQuote {
        #     ...TeamMemberQuoteFragment
        # }
        # expertly {
        #     ...ExpertlyFragment
        # }
        # directContact {
        #     ...DirectContactFragment
        # }
        seo {
            ...MetaFragment
        }
        # tint {
        #     hex
        # }
    }
`;

const TechniquePage: FC<Props> = ({ data, location }) => {
    const entry = data.craft.entry as TechniquePageFragment;

    // return entry.richContent && entry.richContent?.length > 0 ? (
    return <TechniqueTemplate entry={entry} location={location} />;
    // ) : (
    // return <TechniqueOldPage entry={entry} location={location} />;
    // );
};

export default withDefaultTransition(TechniquePage);
